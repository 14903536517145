*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 14px;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

body {
  background-color: var(--backgroundColor);
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  margin: 0;
}

.ril__caption {
  justify-content: center !important;
  font-size: 1.6rem !important;
}

@font-face {
  font-family: "Saulifriend";
  src: local(Saulifriend),
    url("../public/fonts/Saulifriend/Saulifriend.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

.form-bot1 {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

.slick-next:before, .slick-prev:before {
  color: #000;
}

.ant-select-item.ant-select-item-option, .ant-select-item-group {
  border-bottom: .5px solid rgba(0,0,0, .25);
}

@font-face {
  font-family: "Saulifriend";
  src: url("../fonts/Saulifriend/Saulifriend.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lato, bold";
  src: local(Lato-Bold),
    url("../fonts/Lato/Lato-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}